var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('app-text-search-field',{on:{"input":function($event){return _vm.handleSearchInput($event)}}})],1),_c('v-col',{staticClass:"text-right d-md-block d-none",attrs:{"cols":"8"}},[(_vm.$acl.can('employeeCreateEdit'))?_c('v-btn',{staticClass:"text-capitalize",attrs:{"bottom":"","large":"","color":"primary"},on:{"click":function($event){_vm.handleStoreEmployee((_vm.id = null))}}},[_vm._v("Novo")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}})],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.employees.data,"items-per-page":-1,"loading":_vm.loading,"options":_vm.options,"hide-default-footer":"","disable-sort":"","dense":""},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.handleTableUpdate($event)}],"click:row":function($event){return _vm.handleStoreEmployee($event.id)}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.status}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.logout(item.id)}}},[_c('v-list-item-title',[_vm._v("Desconectar")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleEmployeeDialogView(item.id)}}},[_c('v-list-item-title',[_vm._v("Dispositivos logados")])],1)],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.employees.last_page},on:{"input":function($event){return _vm.select($event)}},model:{value:(_vm.searchParams.page),callback:function ($$v) {_vm.$set(_vm.searchParams, "page", $$v)},expression:"searchParams.page"}})],1)],1)],1),_c('StoreEmployeesForm',{ref:"StoreEmployeesForm",on:{"store":function($event){return _vm.select()}}}),_c('EmployeeDialogView',{ref:"EmployeeDialogView"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }