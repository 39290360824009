<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable persistent>
    <v-card>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-title class="text-h6"
              >Dispositivos logados
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(device, index) in devicesLogged" :key="index">
            <template v-if="device.device == 'desktop'">
              <v-list-item-icon>
                <v-icon>mdi-monitor</v-icon>
              </v-list-item-icon>
            </template>

            <template v-else>
              <v-list-item-icon>
                <v-icon>mdi-cellphone</v-icon>
              </v-list-item-icon>
            </template>

            <v-list-item-content>
              <v-list-item-title>{{ device.device }}</v-list-item-title>
              <small>IP: {{ device.ip_address }}</small>
              <small>Navegador: {{ device.browser }}</small>
              <template v-if="device.device !== 'desktop'">
                <small>Modelo: {{ device.device_model }}</small>
                <small>Marca: {{ device.device_brand }}</small>
              </template>
              <small>
                Último de acesso:
                {{ $format.dateBr(device.last_used_at) }}
              </small>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon
                :color="isOnline(device) ? 'success' : 'grey'"
                size="small"
              >
                mdi-circle
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Voltar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { differenceInMinutes } from "date-fns";
export default {
  data: () => ({
    dialog: false,
    devicesLogged: [],
  }),

  methods: {
    async open(employee_id) {
      this.$loading.start();
      await this.$http
        .show("employee/device-logged", employee_id)
        .then((response) => {
          this.devicesLogged = response.tokens;
          this.$loading.finish();
          this.dialog = true;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    isOnline(device) {
      return (
        differenceInMinutes(new Date(), new Date(device.last_used_at)) <= 5
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>