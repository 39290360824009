<template>
  <app-select
    label="Perfis de acesso"
    v-bind="$attrs"
    :dense="dense"
    v-on="$listeners"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :value-comparator="comparator"
    multiple
  />
</template>

<script>
export default {

  props : {
    itemText : {
      default () {
        return (val) => val.name
      }
    },
    
    itemValue : {
      default () {
        return (val) => val
      }
    },
    
    dense : {
      default : true
    }
  },

  data : () => ({
    items : []
  }),

  created() {
    this.select()
  },

  methods : {
    
    comparator(a, b) {
      return a.id == b.id;
    },

    select() {
      this.$http.index('employee/role').then((response) => {
        this.items = response.roles;
      });
    }
  }
}
</script>

<style>

</style>